// src/components/Footer.js

import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} Ben Loreto</p>
    </footer>
  );
}

export default Footer;