// src/components/Header.js

import React from 'react';
import './Header.css';

function Header() {
  return (
    <header>
      <h1>Ben Loreto's Portfolio</h1>
    </header>
  );
}

export default Header;