// src/components/LeetCodeStats.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LeetCodeStats.css';

function LeetCodeStats() {
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);
  const username = 'b3loreto'; // Replace with your LeetCode username

  useEffect(() => {
    const fetchData = async () => {
      const query = `
        query getUserProfile($username: String!) {
          allQuestionsCount {
            difficulty
            count
          }
          matchedUser(username: $username) {
            username
            submitStatsGlobal {
              acSubmissionNum {
                difficulty
                count
              }
            }
          }
        }
      `;

      try {
        const response = await axios.post(
          '/graphql',
          {
            query,
            variables: { username },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const data = response.data;

        if (data.errors) {
          setError('Error fetching data.');
        } else {
          setStats(data.data);
        }
      } catch (err) {
        setError('Error fetching data.');
      }
    };

    fetchData();
  }, [username]);

  if (error) {
    return <div className="leetcode-stats">Error: {error}</div>;
  }

  if (!stats) {
    return <div className="leetcode-stats">Loading...</div>;
  }

  const totalQuestions = stats.allQuestionsCount;
  const submissionStats = stats.matchedUser.submitStatsGlobal.acSubmissionNum;

  return (
    <div className="leetcode-stats">
      <h2>LeetCode Stats</h2>
      <p>Username: {username}</p>
      <table>
        <thead>
          <tr>
            <th>Difficulty</th>
            <th>Solved</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {submissionStats.map((item, index) => {
            const total = totalQuestions.find(
              (q) => q.difficulty === item.difficulty
            ).count;
            return (
              <tr key={index}>
                <td>{item.difficulty}</td>
                <td>{item.count}</td>
                <td>{total}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default LeetCodeStats;